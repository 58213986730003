.SummaryContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  width: 940px;
  height: 500px;
}

.SummaryName {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 300px;
}

.SummaryIntro {
  font-size: 70px;
  font-weight: bold;
  width: 300px;
}

.SummaryTitle {
  font-size: 32px;
  width: 400px;
}

.SummaryImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2vh;
  height: 400px;
  width: 400px;
}

.HireMeButton {
  width: 200px;
  height: 60px;
  margin-top: 20px;
  border: none;
  font-weight: bold;
  background-color: var(--color-1);
  color: white;
}

@media (max-width: 940px) {
  .SummaryContainer {
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
    width: 100vw;
    height: auto;
    margin: 0
  }
  
  .SummaryName {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .SummaryIntro {
    font-size: 2.75em;
  }
  
  .SummaryTitle {
    display: none;
  }

  .SummaryImageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    margin-top: 1rem;
    width: auto;
  }

  .SummaryImageContainer img {
    height: 50vw;
    width: 50vw;
    border-radius: 10vmin;
  }

  .HireMeButton {
    display: none;
  }
}