/* .PortfolioContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 940px;
  margin: 0 auto;
  margin-bottom: 2em;
}

.ProjectContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
} */

.PortfolioContainer {
  background-color: var(--primary-color);
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 90vw;
  overflow-x: auto;
  margin: 0 auto;
  margin-bottom: 2vh;
}

.ProjectContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin: 0 auto;
}

.FilterIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.FilterIconsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90vw;
  margin: 0 auto;
}

.FilterIconImageContainer {
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border: var(--color-1) 2px solid;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  cursor: pointer;
  margin: 0 2px;
}

.FilterIconImageContainer:hover {
  background-color: var(--color-1);
}

.FilterIconImageContainer > img {
  height: 50px;
  width: 50px;
}

.FilterIconContainer > button {
  border-radius: 0.5vmin;
  height: 75px;
  width: 75px;
  border: none;
  background-color: var(--color-1);
  color: white;
  margin-right: 4px;
}

.FilterIconContainer > button:hover {
  background-color: var(--color-2);
}

@media (max-width: 940px) {
  .PortfolioContainer {
    background-color: var(--primary-color);
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 100vw;
    overflow-x: auto;
  }

  .ProjectContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    margin: 0 auto;
  }

  .FilterIconContainer {
    display: none;
  }
  
  .FilterIconContainer > img {
    display: none;
  }
  
  .FilterIconContainer > button {
    display: none;
  }
}