.JobCard {
  width: 60vw
}

.JobContainer {
  display: flex;
  flex-direction: column;
}

.JobCard:hover {
  background-color: var(--color-3);
}

.JobCard {
  display: flex;
  flex-direction: column;
  border: 2px solid var(--color-1);
  border-radius: 1vmin;
  cursor: pointer;
  margin-top: .5em;
  margin-bottom: .5em;
}

.JobCardTop {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 1vh;
}

.JobCardBottom {
  display: grid;
  font-size: 24px;
  grid-template-columns: 3fr 2fr 3fr;
  text-align: center;
  margin-bottom: 1vh;
}

.JobTitle {
  font-size: 36px;
  text-align: center;
  margin: 0;
}

.JobDescriptions {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  max-width: 70vw;
  margin-bottom: 1em;
}

.JobDescriptions div {
  display: flex;
  align-items: center;
  height: auto;
  width: 90%;
  text-align: left;
  border-radius: 1vmin;
}

.ExperienceTitle {
  font-size: 4vmin;
  font-weight: bold;
  text-align: center;
  margin-top: 2vh;
  margin-bottom: 3vh;
}


@media (max-width: 940px) {
  .ExperienceContainer {
    margin-top: 5vh;
  }

  .JobContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2vh;
  }

  .JobCard {
    display: flex;
    flex-direction: column;
    border: 2px solid var(--color-1);
    border-radius: 1vmin;
    cursor: pointer;
    margin: .5em;
    width: 95vw;
  }
  
  .JobCardTop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
  }

  .JobCardBottom {
    display: grid;
    font-size: 1.2em;
    grid-template-columns: 2fr 1fr;
  }
  
  .JobTitle {
    font-size: 1.75em;
  }

  .JobLocation {
    text-align: left;
  }
  
  .JobDescriptions {
    max-width: 95vw;
    margin-right: 1rem;
  }

  .JobDate {
    display: none;
  }
}