.ExperienceContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2vh;
}

.ResumeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 940px;
}

@media (max-width: 940px) {
  .ExperienceContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
  }
  
  .ResumeContainer {
    display: flex;
    align-items: center;
  }
  
  .ResumeComponents {
    display: flex;
    flex-flow: row wrap;
  }
}