.project-card {
  display: flex;
  flex-direction: column;
  margin: 10px;
  border: var(--color-1) 2px solid;
  width: 275px;
  height: 450px;
  border-radius: .5vmin;
  position: relative;
  justify-content: space-between;
}

.ProjectContent {
  display: flex;
  flex-direction: column;
}


.project-name {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  height: 75px;
  width: 100%;
  margin: 0 auto;
  background-color: none;
}

.project-description {


}


.project-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 120px;
}

.project-buttons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 50px;
  width: 100%;
}

.project-buttons > .btn {
  background-color: gray;
  width: 100px;
  height: 40px;
}

.project-stack {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 50px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: var(--color-1) 1px solid;
  /* background-color: green; */
}

.project-stack img {
  height: 30px;
  width: 30px;
}

.ProjectButtons button {
  border-radius: .5vmin;
  height: 30px;
  width: 120px;
  border: none;
  background-color: var(--color-1);
  color: white;
}




@media (max-width: 940px) {
  .ProjectCard {
    border: var(--color-1) .25vmin solid;
    border-radius: 2vmin;
    width: 275px;
    height: 400px;
    display: flex;
    flex-direction: column;
    margin: 3vmin;
  }

  .ProjectDescription{
    font-size: 18px;
    text-align: center;
  }

}