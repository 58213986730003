.AboutMeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 940px;
  height: 500px;
  margin: 0 auto;
  margin-top: 2vh;
}

.AboutMeContent {
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.AboutMeTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 64px;
  font-weight: bold;
  margin-right: 5vmin;
}

.AboutMeDescription {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.AboutMeButtons {
  display: flex;
  justify-content: space-evenly;
  margin-top: 2vmin;
}

.PortfolioButton {
  background-color: var(--color-1);
  color: white;
  border: none;
  width: 175px;
  height: 60px;
}

.ResumeButton {
  background-color: var(--color-1);
  color: white;
  border: none;
  width: 175px;
  height: 60px;
}

.GithubButton {
  background-color: var(--color-1);
  color: white;
  border: none;
  width: 175px;
  height: 60px;
}

@media (max-width: 940px) {
  .AboutMeContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    width: 100vw;
    margin-bottom: 2vh;
  }

  .AboutMeContent {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    margin: 0 auto;
    width: 95vw;
  }

  .AboutMeTitle {
    font-size: 24px;
    margin: 0 auto;
  }

  .AboutMeDescription {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    width: 85vw;
  }

  .AboutMeButtons {
    display: flex;
    justify-content: space-evenly;
  }
  
  .PortfolioButton {
    margin: 0 auto;
    width: 80px;
    height: 50px;
    background-color: var(--color-1);
    border: none;
    color: white;
    font-size: 20px;
  }
  
  .ResumeButton {
    margin: 0 auto;
    width: 80px;
    height: 50px;
    background-color: var(--color-1);
    border: none;
    color: white;
    font-size: 20px;
  }

  .GithubButton {
    margin: 0 auto;
    width: 80px;
    height: 50px;
    background-color: var(--color-1);
    border: none;
    color: white;
    font-size: 20px;
  }
}
