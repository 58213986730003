.devicon {
  height: 50px;
  width: 50px;
}

.services-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-flow: row wrap;
}

@media (max-width: 940px) {
  .servicesContainer {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: auto;
  }

  .skill-carousel {
    display: none;
  }

  .body {
    
  }

}

/* Add breakpoints as needed */
@media (max-width: 767px) {
  /* For mobile screens, enable horizontal scrolling */
  .skill-container {
    overflow-x: scroll;
    white-space: nowrap;
  }

  /* Adjust card size for mobile */
  .serviceCard {
    width: 300px; /* Or adjust as needed */
  }
}

/* Additional styling for larger screens */
@media (min-width: 768px) {
  .serviceCard {
    /* Add styling for desktop view, if necessary */
  }
}