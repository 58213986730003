.NavBarContainer {
  display: flex;
  justify-content: center;
  background-color: var(--color-3);
  width: 100vw;
}

.NavBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  top: 0;
  z-index: 999;
  width: 90vw;
}

.borderOne {
  border-bottom: 8px solid var(--color-1);
  width: 100vw;
}
.borderTwo {
  border-bottom: 8px solid var(--color-2);
  width: 100vw;
}
.borderThree {
  border-bottom: 8px solid var(--color-3);
  width: 100vw;
}
.borderFour {
  width: 100vw;
  border-bottom: 8px solid var(--color-4);
}
.borderFive {
  border-bottom: 8px solid var(--color-5);
  width: 100vw;
}


.Logo {
  color: var(--color-1);
  font-size: 3em;
  font-weight: bold;
  margin-left: 32px;
}

.NavLinks a {
  text-decoration: none;
  color: var(--color-1);
  font-size: 20px;
}

.NavLink {
  margin-right: 32px;
}

@media (max-width: 940px) {
  .NavBarContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    margin: 0 auto;
  }

  .NavBar {
    background-color: var(--color-1);
    display: flex;
    width: 100vw;
    height: 4em;
    position: static;
    top: 0;
  }
  
  .Logo {
    color: var(--color-3);
    font-size: 2.5em;
    font-weight: bold;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    margin: 0;
  }

  .MobileNavMenu {
    display: flex;
    flex-flow: row wrap;
    margin-top: 2vh;
    margin-bottom: 2vh;
    justify-content: center;
  }
  
  .MobileLink {
    display: flex;
    justify-content: center;
    align-items: center;
    border: var(--color-1) solid .15vmin;
    text-align: center;
    text-decoration: none;
    color: var(--color-3);
    font-size: 1em;
    height: 36px;
    color: black;
    margin: 1vmin;
    width: 10rem;
    border-radius: 2vmin;
  }

  .HamburgerMenu {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: left;

    margin: 0 auto;
    margin-left: 5vw;
  }
  
  .HamburgerMenu.menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: left;

    width: 100vw;
    margin: 0 auto;
    margin-left: 5vw;
  }
  
}

