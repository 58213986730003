.serviceCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 250px;
  margin: 5px;
  border: var(--color-1) solid 2px;
}

.serviceTitle {
  font-size: 2em;
  font-weight: bold;
  text-align: left;
  margin-top: 2vmin;
}

.serviceDescription {
  margin-top: 2vmin;
  font-size: 1em;
}

@media (max-width: 940px) {
  .summaryContainer {
    display: flex;
    flex-direction: row;
  }

  .serviceCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 2vmin;
    height: 75px;
    border: .5vmin solid var(--color-1);
  }

  .icon {
    height: .5em;
    width: .5em;
  }

  .serviceTitle {
    font-size: 1em;
    font-weight: bold;
    text-align: left;
  }

  .serviceDescription {
    display: none;
  }
}
