.FooterContainer {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  margin: 0 auto;
}

.ContactContainer {
  grid-column: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.LargeContactContainer {
  display: flex;
  flex-direction: row;
}

.ContactInfo {
  display: flex;
  flex-direction: row;
  margin-left: 2vw;
}

.FooterMainInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 2vw;
}

.FooterName {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  font-weight: bold;
}

.FooterContactMeNow {
  display: none
}

.LeftFooter {
  grid-column: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20vw;
}

.FooterTravels {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
}

@media (max-width: 940px) {
  .FooterContainer {
    margin: 0 auto;
    display: flex;
    width: 100vw;
    margin-top: 3vh;
    margin-bottom: 3vh;
    background-color: var(--primary-color);
  }

  .ContactContainer {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
  }

  .ContactInfo {
    display: flex;
    flex-direction: column-reverse;
    margin: 0 auto;
  }

  .FooterMainInfo {
    margin: 0 auto;
  }

  .FooterName {
    display: none;
  }

  .FooterContactMeNow {
    text-align: center;
    font-weight: bold;
  }

  .FooterIconLinks {
    margin: 0 auto;
  }

  .FooterIconLinks a {
    margin: 0 auto;
  }

  .MobileFooterIcons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-align: center;
    width: 90vw;
    color: var(--color-1);
  }
}