.ContactFormContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--color-5);
  padding: 8px;
  width: 600px;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 1vmin;
  border: 4px solid var(--color-4);
}

.ContactFooterContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 1rem;
}

/* .ContactFooterContainer div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1vh;
  cursor: pointer;
} */

.ContactFormLeft {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: left;
  width: 220px;
}

.ContactFormRight {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100px;
}

.ContactFormTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.ContactForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 8px;
}

.ContactFormName {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 410px;
}

.ContactFormName input {
  width: 200px;
  height: 32px;
}

.ContactForm input {
  border-radius: .25vmin;
  border: 2px solid var(--color-4);
  font-size: 16px;
  padding-left: 8px;
}
.ContactForm textarea {
  border-radius: .25vmin;
  border: 2px solid var(--color-4);
  font-size: 16px;
  padding-left: 8px;
  padding-top: 4px;
}

.ContactMessage {
  margin-top: 1vmin;
  resize: none;
}

.ContactForm button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ContactFormArea {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ContactFormButton {
  width: 200px;
  height: 50px;
  font-size: 24px;
  margin-top: 16px;
  border: none;
  background-color: var(--color-1);
  color: white;
  border-radius: 1vmin;
}

.FooterIconLinks {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

#linkedin {

}

#github {

}

@media (max-width: 940px) {
  .ContactFormContainer {
    display: flex;
    flex-direction: column;
    margin: 1vh 0 2vh 0;
  }
  
  .ContactFormTitle {
    display: none;
  }
  
  .ContactForm {
    margin: 0 auto;
  }
  
  .ContactFormName {
    margin: 0 auto;

  }
  
  .ContactFormName input {
    margin: 0 auto;
  }
  
  input, textarea {
    border-radius: .25vmin;
    border: .15vmin solid var(--primary-color);
    font-size: 4vmin;
  }
  
  .ContactMessage {
    margin-top: 1vmin;
    margin-bottom: 1vmin;
    resize: none;
  }
  
  .ContactForm button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

  }
  
  .ContactFormArea {
    display: flex;
    flex-direction: column;
    margin: 0 auto;

  }
  
  .ContactFormButton {
    width: 50vw;
    height: 6vh;
    border: none;
    border-radius: 2vmin;
    background-color: var(--accent-color-1);
    color: white;
    margin: 0 auto;
  }
}