:root {
  --color-1: #f4a127;
  --color-2: #e5771e;
  --color-3: #ffecb4;
  --color-4: #5a3d2b;
  --color-5: #75c8ae;
  --image-opaque: rgba(185, 44, 44, 0.5);
} 

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
