.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 940px;
  margin: 0 auto;
}

.SectionTitle {
  font-size: 4vmin;
  font-weight: bold;
  text-align: center;
  margin-top: 2vh;
  margin-bottom: 3vh;
}

@media (max-width: 940px) {
  .App {
    width: 100vw;
    overflow-x: hidden;
  }

  .SectionTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }

  .AppCont {
    overflow: hidden;
  }

}